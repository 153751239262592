import React, { Component, useContext } from 'react';
import { Helmet } from "react-helmet"
import MenuBar from '../components/MenuBar';
import { graphql, navigate } from 'gatsby';
import ProjectPreview from '../components/ProjectPreview';
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image';
import '../styles/product.css'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import ImageFullScreen from '../components/ImageFullScreen';
import { StoreContext } from "../context/store-context"
import { useState, useEffect } from 'react';
import ErrorBoundary from '../components/ErrorBoundary';
import Dock from '../components/Dock';

class product extends Component {
    static contextType = StoreContext;

    state = {
        inStock: true
    };

    componentDidMount() {
        const product = get(this, 'props.data.shopifyProduct')

        const productVariants = get(this, 'props.data.shopifyProduct.variants')

        if (productVariants[0].availableForSale == false) {
            this.setState({ inStock: false });
        }
    }

    render() {
        const addVariantToCart = this.context.addVariantToCart;

        const product = get(this, 'props.data.shopifyProduct')

        const productVariants = get(this, 'props.data.shopifyProduct.variants')

        function GalleryItem({ image, imageSrc, mainImageSrc }) {
            if (imageSrc == mainImageSrc) return; //stops product image from showing up in gallery
            return <GatsbyImage image={image} className='product-gallery-image' />;
        }

        const handleAddToCart = async () => {
            var variantId = document.getElementById('variant-select').value;
            await this.context.addVariantToCart(variantId, 1)
            console.log('added to cart ' + variantId)
            navigate('/bag')
        }



        const setInStock = (e) => {
            this.setState({ inStock: e });
            console.log(this.state.inStock);
        }

        const checkInStock = () => {
            console.log('checking in stock');
            var variantId = document.getElementById('variant-select').value;
            if (variantId == null) variantId = productVariants[0].shopifyId;
            var selectedVariant = productVariants.find(variant => variant.shopifyId == variantId);
            if (selectedVariant.availableForSale == false) {
                setInStock(false);
            }
            else {
                setInStock(true);
            }
        }

        const handleOptionDisplay = (option) => {
            let input = option + "";
            let output = input.replace(product.title + " - ", "");

            return output;
        }

        return (
            <main>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="black" />
                    <title>{product.title} - Shop - HYSG</title>
                    <link rel="canonical" href="https://mysite.com/example" />
                </Helmet>
                <MenuBar />
                <div className='product-container'>
                    <div className='product-left-image'>
                        <GatsbyImage image={product.featuredImage.gatsbyImageData} className='product-main-image' />
                        <div className='product-desktop-gallery'>
                            {product.media.map(node => (
                                <GalleryItem
                                    image={node.preview.image.gatsbyImageData}
                                    imageSrc={node.preview.image.src}
                                    mainImageSrc={product.featuredImage.src}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='product-right-details'>
                        <h1 className='title product-title'>{product.title}</h1>
                        <h1 className='title product-price'>${product.priceRangeV2.maxVariantPrice.amount}</h1>
                        <p className='product-description'>{product.description}</p>
                        <div className='product-buttons'>
                            {/*product.options.map(options => (
                                <select className='options-select'>
                                    {
                                        options.values.map(value => (
                                            <option className='text-center' value={value}>{value}</option>
                                        ))
                                    }
                                </select>
                                ))*/}
                            <select className='options-select' id="variant-select" onChange={checkInStock}>
                                {
                                    product.variants.map(variant => (
                                        <option className='text-center' value={variant.shopifyId} key={variant.displayName}>{handleOptionDisplay(variant.displayName)}</option>
                                    ))
                                }
                            </select>
                            {this.state.inStock ?
                                <button className='cart-button' onClick={handleAddToCart}>add to bag</button>
                                :
                                <button className='cart-button' disabled>out of stock</button>
                            }

                        </div>
                    </div>
                    <div className='product-gallery'>
                        {product.media.map(node => (
                            <GalleryItem
                                image={node.preview.image.gatsbyImageData}
                                imageSrc={node.preview.image.src}
                                mainImageSrc={product.featuredImage.src}
                            />
                        ))}
                    </div>
                </div>
                <Dock />
            </main >
        );
    }
}

export const query = graphql`
  query($url: String!){
    shopifyProduct(handle: {eq: $url}) {
      title
      handle
      featuredImage {
        gatsbyImageData(width: 1080)
        src
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
      }
      description
      variants {
        displayName
        shopifyId
        availableForSale
        selectedOptions {
          value
          name
        }
      }
      options {
        values
        shopifyId
      }
      media {
        preview {
            image {
                gatsbyImageData(width: 1080)
                src
              }
        }
      }
    }
  }
`

export default product;